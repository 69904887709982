
@media only screen and (max-width: 1000px) {
    .carouselCard{
        width: 100%;
        
    }
  }
@media only screen and (min-width: 1000px) {
    .carouselCard{
        width: 50%;
        margin: auto;
        max-width: 600px;
    }
  }


