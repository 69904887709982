.titleColor{
    color: #266bff
}
.title{
    font-family: 'Bebas Neue', cursive;
  }

  @media screen and (min-width: 1000px) {
    .title{
        font-size: 200px
      }
  }
  @media screen and (max-width: 1000px) {
    .title{
        font-size: 20vw
      }
}

.centerContent{
    margin-left: auto;
    margin-right: auto;

}