

.introcss{
    border-radius: 0px;
}
.center{
    text-align: center;
}

.titleFont{
    font-family: 'Source Code Pro', monospace;
    font-size: 60px !important;
    font-weight: 500;
}

.landing-page{
    background-color: white;
    height: 100%;
    width: 100%;
    color: black;

}

.highlight{
    color: rgb(0, 94, 255)
}

.horizontalCenter{
    margin: auto;
    width: 50%;
}


.user {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  .one {
    background-image: url('https://via.placeholder.com/400x200');
  }